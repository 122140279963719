import React, { FC } from 'react';
import { ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import {
  ChubbsWrapper,
} from '@/components/ui/Chubbs/ChubbsWrapper';
import { colorTokens } from '@/components/ui/colorTokens';

export const ChubbsWorkingDark: FC<ChubbsProps> = React.memo((props) => (
  <ChubbsWrapper initialHeight={157} initialWidth={92} {...props}>
    <g clipPath="url(#clip0_19425_3739)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.34012 53.6601H35.1201C36.2323 53.7216 37.2983 54.1255 38.1719 54.8166C39.0456 55.5076 39.6842 56.4519 40.0001 57.52L44.5701 74.81C44.7325 75.2551 44.7822 75.7334 44.7147 76.2023C44.6472 76.6712 44.4648 77.1161 44.1835 77.4974C43.9023 77.8786 43.5311 78.1843 43.103 78.3872C42.6749 78.59 42.2033 78.6838 41.7301 78.6601H14.0001C12.8888 78.6007 11.823 78.1987 10.9492 77.5094C10.0755 76.82 9.43653 75.8771 9.12013 74.81L4.50013 57.52C4.33831 57.0745 4.28899 56.5958 4.35654 56.1266C4.42409 55.6573 4.60643 55.2121 4.88738 54.8302C5.16832 54.4484 5.53918 54.1418 5.96705 53.9376C6.39492 53.7335 6.86656 53.6382 7.34012 53.6601Z"
        fill="#191A1F"
      />
      <path
        d="M7.34012 53.6601H35.1201C36.2323 53.7216 37.2983 54.1255 38.1719 54.8166C39.0456 55.5076 39.6842 56.4519 40.0001 57.52L44.5701 74.81C44.7325 75.2551 44.7821 75.7334 44.7147 76.2023C44.6472 76.6712 44.4648 77.1161 44.1835 77.4974C43.9023 77.8786 43.5311 78.1843 43.103 78.3872C42.6749 78.59 42.2033 78.6838 41.7301 78.6601H14.0001C12.8888 78.6007 11.823 78.1987 10.9492 77.5094C10.0755 76.82 9.43653 75.8771 9.12013 74.81L4.50013 57.52C4.33831 57.0745 4.28899 56.5958 4.35654 56.1266C4.42409 55.6573 4.60643 55.2121 4.88738 54.8302C5.16832 54.4484 5.53918 54.1418 5.96705 53.9376C6.39492 53.7335 6.86656 53.6382 7.34012 53.6601V53.6601Z"
        stroke="#54575F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M38.04 78.4099H12.9C11.9721 78.4099 11.22 79.1621 11.22 80.0899C11.22 81.0177 11.9721 81.7699 12.9 81.7699H38.04C38.9678 81.7699 39.72 81.0177 39.72 80.0899C39.72 79.1621 38.9678 78.4099 38.04 78.4099Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M38.04 78.4099H12.9C11.9721 78.4099 11.22 79.1621 11.22 80.0899C11.22 81.0177 11.9721 81.7699 12.9 81.7699H38.04C38.9678 81.7699 39.72 81.0177 39.72 80.0899C39.72 79.1621 38.9678 78.4099 38.04 78.4099Z"
        stroke="#54575F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M30.29 78.4099H12.9C11.9721 78.4099 11.22 79.1621 11.22 80.0899C11.22 81.0177 11.9721 81.7699 12.9 81.7699H30.29C31.2178 81.7699 31.97 81.0177 31.97 80.0899C31.97 79.1621 31.2178 78.4099 30.29 78.4099Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M30.29 78.4099H12.9C11.9721 78.4099 11.22 79.1621 11.22 80.0899C11.22 81.0177 11.9721 81.7699 12.9 81.7699H30.29C31.2178 81.7699 31.97 81.0177 31.97 80.0899C31.97 79.1621 31.2178 78.4099 30.29 78.4099Z"
        stroke="#54575F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 139.08H50C50.1674 139.087 50.3259 139.157 50.4444 139.276C50.5629 139.394 50.6326 139.553 50.64 139.72L51.59 153.88H18C17.37 147.95 21.71 146.15 26.84 143.61C28.79 142.14 29 139.08 29 139.08Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M29 139.08H50C50.1673 139.087 50.3259 139.157 50.4444 139.276C50.5629 139.394 50.6326 139.553 50.64 139.72L51.59 153.88H18C17.37 147.95 21.71 146.15 26.84 143.61C28.79 142.14 29 139.08 29 139.08Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.9401 151.62H17.9601C17.6066 151.62 17.3201 151.907 17.3201 152.26V155.29C17.3201 155.644 17.6066 155.93 17.9601 155.93H51.9401C52.2935 155.93 52.5801 155.644 52.5801 155.29V152.26C52.5801 151.907 52.2935 151.62 51.9401 151.62Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M51.9401 151.62H17.9601C17.6066 151.62 17.3201 151.907 17.3201 152.26V155.29C17.3201 155.644 17.6066 155.93 17.9601 155.93H51.9401C52.2935 155.93 52.5801 155.644 52.5801 155.29V152.26C52.5801 151.907 52.2935 151.62 51.9401 151.62Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5298 139.38L28.0898 141.15C33.6482 140.251 39.2545 139.68 44.8798 139.44L28.5298 139.38Z"
        fill="#191A1F"
      />
      <path
        d="M28.5298 139.38L28.0898 141.15C33.6482 140.251 39.2545 139.68 44.8798 139.44L28.5298 139.38Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.05 139.12H33.05C33.2182 139.125 33.3781 139.194 33.497 139.313C33.616 139.432 33.685 139.592 33.69 139.76L34.69 153.92H1.07004C0.44004 147.99 4.78004 146.19 9.90004 143.65C11.89 142.18 12.05 139.12 12.05 139.12Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M12.05 139.12H33.05C33.2182 139.125 33.3781 139.194 33.497 139.313C33.616 139.432 33.685 139.592 33.69 139.76L34.69 153.92H1.07004C0.44004 147.99 4.78004 146.19 9.90004 143.65C11.89 142.18 12.05 139.12 12.05 139.12Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.0499 151.65H1.06993C0.716469 151.65 0.429932 151.936 0.429932 152.29V155.32C0.429932 155.673 0.716469 155.96 1.06993 155.96H35.0499C35.4034 155.96 35.6899 155.673 35.6899 155.32V152.29C35.6899 151.936 35.4034 151.65 35.0499 151.65Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M35.0499 151.65H1.06993C0.716469 151.65 0.429932 151.936 0.429932 152.29V155.32C0.429932 155.673 0.716469 155.96 1.06993 155.96H35.0499C35.4034 155.96 35.6899 155.673 35.6899 155.32V152.29C35.6899 151.936 35.4034 151.65 35.0499 151.65Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3399 139.42L11.8999 141.18C17.4597 140.295 23.0655 139.727 28.6899 139.48L12.3399 139.42Z"
        fill="#191A1F"
      />
      <path
        d="M12.3399 139.42L11.8999 141.18C17.4597 140.295 23.0655 139.727 28.6899 139.48L12.3399 139.42Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.7801 14.8001C49.7801 23.3501 49.7801 23.2101 49.7801 31.8001C49.9301 52.9101 37.4101 53.3301 38.8601 82.4301C39.0701 86.6801 42.5401 86.1501 43.1901 91.8601C47.9901 93.0301 60.8401 88.3401 65.6401 89.5001C70.4301 88.3401 82.3001 97.9301 87.0901 96.7601C88.3801 91.1201 89.9501 87.7601 89.9101 83.3201C89.7201 56.3201 78.5201 49.6001 78.5401 33.3201V14.8601C78.5701 -4.13992 49.8101 -3.48991 49.7801 14.8001Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M49.7801 14.8001C49.7801 23.3501 49.7801 23.2101 49.7801 31.8001C49.9301 52.9101 37.4101 53.3301 38.8601 82.4301C39.0701 86.6801 42.5401 86.1501 43.1901 91.8601C47.9901 93.0301 60.8401 88.3401 65.6401 89.5001C70.4301 88.3401 82.3001 97.9301 87.0901 96.7601C88.3801 91.1201 89.9501 87.7601 89.9101 83.3201C89.7201 56.3201 78.5201 49.6001 78.5401 33.3201V14.8601C78.5701 -4.13992 49.8101 -3.48991 49.7801 14.8001Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.81 81.77C24.47 82.26 10.27 85.85 10.89 105.47C10.89 124.62 10.81 138.42 10.89 139.41C10.1 139.66 50.64 139.61 50.64 139.61C50.64 139.61 50.35 116.97 50.48 112.56C50.59 108.85 50.7701 105.84 56.03 105.86C56.96 105.86 68.67 105.86 69.65 105.92C77.73 106.22 91.85 106.92 91.59 90.92C91.59 88.52 90.84 86.13 89.84 82.48C89.8153 82.2373 89.8153 81.9927 89.84 81.75H43.3601C40.0601 81.77 36 81.59 32.81 81.77Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M32.81 81.77C24.47 82.26 10.27 85.85 10.89 105.47C10.89 124.62 10.81 138.42 10.89 139.41C10.1 139.66 50.64 139.61 50.64 139.61C50.64 139.61 50.35 116.97 50.48 112.56C50.59 108.85 50.77 105.84 56.03 105.86C56.96 105.86 68.67 105.86 69.65 105.92C77.73 106.22 91.85 106.92 91.59 90.92C91.59 88.52 90.84 86.13 89.84 82.48C89.8153 82.2373 89.8153 81.9927 89.84 81.75H43.3601C40.0601 81.77 36 81.59 32.81 81.77Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.91 24.7699C51.2427 24.7719 51.5685 24.675 51.8461 24.4916C52.1236 24.3082 52.3405 24.0466 52.4692 23.7398C52.5979 23.433 52.6326 23.0949 52.5689 22.7683C52.5052 22.4418 52.346 22.1415 52.1115 21.9055C51.8769 21.6696 51.5776 21.5086 51.2514 21.4429C50.9253 21.3773 50.587 21.41 50.2794 21.5368C49.9718 21.6637 49.7089 21.879 49.5238 22.1555C49.3388 22.432 49.24 22.7572 49.24 23.0899C49.24 23.5337 49.4156 23.9595 49.7285 24.2743C50.0414 24.5891 50.4662 24.7672 50.91 24.7699Z"
        fill="white"
      />
      <path
        d="M50.91 24.7699C51.2427 24.7719 51.5685 24.675 51.8461 24.4916C52.1236 24.3082 52.3405 24.0466 52.4692 23.7398C52.5979 23.433 52.6326 23.0949 52.5689 22.7683C52.5052 22.4418 52.346 22.1415 52.1115 21.9055C51.8769 21.6696 51.5776 21.5086 51.2514 21.4429C50.9253 21.3773 50.587 21.41 50.2794 21.5368C49.9718 21.6637 49.7089 21.879 49.5238 22.1555C49.3388 22.432 49.24 22.7572 49.24 23.0899C49.24 23.5337 49.4156 23.9595 49.7285 24.2743C50.0414 24.5891 50.4662 24.7672 50.91 24.7699Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66 105.88C74.12 105.66 77 99.2699 76.8 92.3899"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.4399 17.1801C50.9489 16.7268 50.3315 16.4338 49.6699 16.3401"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.27 36.6899H49.77C49.2396 36.6899 48.7309 36.9007 48.3558 37.2757C47.9807 37.6508 47.77 38.1595 47.77 38.6899C47.77 39.2204 47.9807 39.7291 48.3558 40.1041C48.7309 40.4792 49.2396 40.6899 49.77 40.6899H78.27C78.8005 40.6899 79.3092 40.4792 79.6842 40.1041C80.0593 39.7291 80.27 39.2204 80.27 38.6899C80.27 38.1595 80.0593 37.6508 79.6842 37.2757C79.3092 36.9007 78.8005 36.6899 78.27 36.6899Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M78.27 36.6899H49.77C49.2396 36.6899 48.7309 36.9007 48.3558 37.2757C47.9807 37.6508 47.77 38.1595 47.77 38.6899C47.77 39.2204 47.9807 39.7291 48.3558 40.1041C48.7309 40.4792 49.2396 40.6899 49.77 40.6899H78.27C78.8005 40.6899 79.3092 40.4792 79.6842 40.1041C80.0593 39.7291 80.27 39.2204 80.27 38.6899C80.27 38.1595 80.0593 37.6508 79.6842 37.2757C79.3092 36.9007 78.8005 36.6899 78.27 36.6899Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M78.5399 13.1399H34.7"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.14 40.71L48.7 42.48C54.2582 41.5825 59.8646 41.0148 65.49 40.78L49.14 40.71Z"
        fill="#191A1F"
      />
      <path
        d="M49.14 40.71L48.7 42.48C54.2582 41.5825 59.8646 41.0148 65.49 40.78L49.14 40.71Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.23 13.0701L49.79 14.8401C55.3481 13.9417 60.9547 13.3741 66.58 13.1401L50.23 13.0701Z"
        fill="#191A1F"
      />
      <path
        d="M50.23 13.0701L49.79 14.8401C55.3481 13.9417 60.9547 13.3741 66.58 13.1401L50.23 13.0701Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.3 31.2401C49.59 29.6401 51.99 27.0101 53.68 28.8401C53.94 29.1301 53 30.8801 52.3 31.2401Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M52.3 31.2401C49.59 29.6401 51.99 27.0101 53.68 28.8401C53.94 29.1301 53 30.8801 52.3 31.2401Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.7401 23.3999C49.7142 23.3999 49.6887 23.4051 49.665 23.4153C49.6412 23.4254 49.6198 23.4403 49.6021 23.459C49.5843 23.4778 49.5705 23.4999 49.5616 23.5241C49.5526 23.5483 49.5487 23.5741 49.5501 23.5999C49.5501 23.6503 49.5701 23.6986 49.6057 23.7343C49.6413 23.7699 49.6897 23.7899 49.7401 23.7899C49.7897 23.7875 49.8366 23.7667 49.8717 23.7316C49.9068 23.6964 49.9276 23.6495 49.9301 23.5999C49.9301 23.5485 49.9104 23.4991 49.8751 23.4619C49.8397 23.4247 49.7914 23.4025 49.7401 23.3999Z"
        fill="#191A1F"
      />
      <path
        d="M49.7401 23.3999C49.7142 23.3999 49.6887 23.4051 49.665 23.4153C49.6412 23.4254 49.6198 23.4403 49.6021 23.459C49.5843 23.4778 49.5705 23.4999 49.5616 23.5241C49.5526 23.5483 49.5487 23.5741 49.5501 23.5999C49.5501 23.6503 49.5701 23.6986 49.6057 23.7343C49.6413 23.7699 49.6897 23.7899 49.7401 23.7899C49.7897 23.7875 49.8366 23.7667 49.8717 23.7316C49.9068 23.6964 49.9276 23.6495 49.9301 23.5999C49.9301 23.5485 49.9104 23.4991 49.8751 23.4619C49.8397 23.4247 49.7914 23.4025 49.7401 23.3999V23.3999Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.75 32.56C50.64 32.56 51.98 31.56 52.86 30.73"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M34.3398 139.4V109.23C34.3398 107.5 34.8998 106.14 36.9398 105.98L56.0098 105.86"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.71 109.42C65.3411 109.67 65.8478 110.159 66.1194 110.781C66.3911 111.403 66.4057 112.107 66.16 112.74L50 154.08C49.752 154.713 49.2629 155.221 48.6403 155.493C48.0176 155.765 47.3125 155.778 46.68 155.53C46.0474 155.282 45.5393 154.793 45.2674 154.17C44.9954 153.548 44.982 152.843 45.23 152.21L61.36 110.88C61.4825 110.563 61.6667 110.273 61.9019 110.028C62.1372 109.783 62.4187 109.587 62.7302 109.451C63.0418 109.315 63.3772 109.242 63.717 109.237C64.0568 109.232 64.3943 109.294 64.71 109.42Z"
        fill="#191A1F"
      />
      <path
        d="M64.71 109.42C65.3411 109.67 65.8478 110.159 66.1194 110.781C66.3911 111.403 66.4057 112.107 66.16 112.74L50 154.08C49.752 154.713 49.2629 155.221 48.6403 155.493C48.0176 155.765 47.3125 155.778 46.68 155.53C46.0474 155.282 45.5393 154.793 45.2674 154.17C44.9954 153.548 44.982 152.843 45.23 152.21L61.36 110.88C61.4825 110.563 61.6667 110.273 61.9019 110.028C62.1372 109.783 62.4187 109.587 62.7302 109.451C63.0418 109.315 63.3772 109.242 63.717 109.237C64.0568 109.232 64.3943 109.294 64.71 109.42Z"
        fill="#191A1F"
        stroke="#54575F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.34 109.42C67.7071 109.668 67.1981 110.156 66.9245 110.779C66.6508 111.401 66.6349 112.106 66.88 112.74L83 154.08C83.248 154.713 83.7371 155.221 84.3597 155.493C84.9823 155.765 85.6875 155.778 86.32 155.53C86.9526 155.282 87.4607 154.793 87.7326 154.17C88.0045 153.548 88.018 152.843 87.77 152.21L71.63 110.88C71.3841 110.253 70.9008 109.748 70.2849 109.474C69.669 109.201 68.9702 109.181 68.34 109.42Z"
        fill="#191A1F"
      />
      <path
        d="M68.34 109.42C67.7071 109.668 67.1981 110.156 66.9245 110.779C66.6508 111.401 66.6349 112.106 66.88 112.74L83 154.08C83.248 154.713 83.7371 155.221 84.3597 155.493C84.9823 155.765 85.6875 155.778 86.32 155.53C86.9526 155.282 87.4607 154.793 87.7326 154.17C88.0045 153.548 88.018 152.843 87.77 152.21L71.63 110.88C71.3841 110.253 70.9008 109.748 70.2849 109.474C69.669 109.201 68.9702 109.181 68.34 109.42V109.42Z"
        stroke="#54575F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.24 106.66C66.9191 106.665 67.5687 106.938 68.0479 107.419C68.5272 107.9 68.7974 108.551 68.8 109.23L68.75 153.61C68.7719 153.96 68.7218 154.311 68.6028 154.642C68.4838 154.972 68.2984 155.274 68.0581 155.53C67.8177 155.786 67.5276 155.99 67.2054 156.129C66.8833 156.268 66.536 156.34 66.185 156.34C65.834 156.34 65.4867 156.268 65.1646 156.129C64.8425 155.99 64.5523 155.786 64.3119 155.53C64.0716 155.274 63.8862 154.972 63.7672 154.642C63.6482 154.311 63.5981 153.96 63.62 153.61V109.23C63.6213 108.889 63.6903 108.551 63.823 108.236C63.9558 107.922 64.1496 107.637 64.3933 107.398C64.637 107.159 64.9257 106.97 65.2427 106.844C65.5597 106.717 65.8987 106.655 66.24 106.66Z"
        fill="#191A1F"
      />
      <path
        d="M66.24 106.66C66.9191 106.665 67.5687 106.938 68.0479 107.419C68.5272 107.9 68.7974 108.551 68.8 109.23L68.75 153.61C68.7719 153.96 68.7218 154.311 68.6028 154.642C68.4838 154.972 68.2984 155.274 68.0581 155.53C67.8177 155.786 67.5276 155.99 67.2054 156.129C66.8833 156.268 66.536 156.34 66.185 156.34C65.834 156.34 65.4867 156.268 65.1646 156.129C64.8425 155.99 64.5523 155.786 64.3119 155.53C64.0716 155.274 63.8862 154.972 63.7672 154.642C63.6482 154.311 63.5981 153.96 63.62 153.61V109.23C63.6213 108.889 63.6903 108.551 63.823 108.236C63.9558 107.922 64.1496 107.637 64.3933 107.398C64.637 107.159 64.9257 106.97 65.2427 106.844C65.5597 106.717 65.8987 106.655 66.24 106.66Z"
        fill="#191A1F"
        stroke="#54575F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.4499 54.9999C62.7599 46.5099 51.7899 41.6999 48.2199 47.4699C44.7099 52.4099 42.8399 57.0699 38.2199 61.3499C36.9999 62.4799 26.6899 66.5599 23.6799 67.5099C20.9599 68.3599 18.8099 72.1199 18.4599 75.5799C18.1899 78.2699 20.5899 79.0199 22.0599 76.9399C22.0599 78.3999 23.6099 78.6099 24.6199 78.0899C25.2093 77.6432 25.6854 77.0643 26.0099 76.3999C26.0099 76.5099 25.5699 78.3999 27.2099 78.2899C28.8499 78.1799 28.9999 76.3999 29.6599 76.2899C32.0727 75.7857 34.4482 75.1175 36.7699 74.2899C44.0399 71.7799 49.7699 70.2899 53.2899 62.9799C53.7399 62.0499 56.7899 55.9199 57.4199 54.9799"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M47 47.2C44.5 52.59 42.84 56.83 38.25 61.1C37.03 62.24 26.72 66.3199 23.71 67.2599C20.99 68.1099 18.84 72.1599 18.49 75.6199C18.22 78.3099 20.62 79.0599 22.09 76.9799C22.09 78.4399 23.64 78.65 24.65 78.13C25.2393 77.6833 25.7154 77.1044 26.04 76.44C26.04 76.55 25.6 78.44 27.24 78.33C28.88 78.22 29.03 76.44 29.69 76.33C32.1027 75.8258 34.4782 75.1575 36.8 74.33C44.07 71.82 49.8 70.33 53.32 63.02C53.77 62.09 56.82 55.96 57.45 55.02"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M22 77.05C22.46 76.12 22.85 74.71 23.75 74.05"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M26.3701 74.49C27.1332 75.0669 28.0044 75.4845 28.9321 75.7181C29.8597 75.9518 30.8248 75.9966 31.7701 75.85"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39 75.4099C39.84 75.5199 46.69 70.7999 47.71 69.8299C48.21 69.3499 49.9 67.95 47.78 69.57C45.0369 71.4399 42.0259 72.883 38.85 73.85C38.8046 74.3456 38.8046 74.8443 38.85 75.3399L39 75.4099Z"
        fill="#191A1F"
      />
      <path
        d="M39 75.4099C39.84 75.5199 46.69 70.7999 47.71 69.8299C48.21 69.3499 49.9 67.95 47.78 69.57C45.0369 71.4399 42.0259 72.883 38.85 73.85C38.8046 74.3456 38.8046 74.8443 38.85 75.3399L39 75.4099Z"
        stroke="#191A1F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M82.16 105.92H50.05C47.134 105.92 44.77 108.284 44.77 111.2C44.77 114.116 47.134 116.48 50.05 116.48H82.16C85.0761 116.48 87.44 114.116 87.44 111.2C87.44 108.284 85.0761 105.92 82.16 105.92Z"
        fill="#191A1F"
      />
      <path
        d="M82.16 105.92H50.05C47.134 105.92 44.77 108.284 44.77 111.2C44.77 114.116 47.134 116.48 50.05 116.48H82.16C85.0761 116.48 87.44 114.116 87.44 111.2C87.44 108.284 85.0761 105.92 82.16 105.92Z"
        stroke="#54575F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_19425_3739">
        <rect width="92" height="157" fill="white" />
      </clipPath>
    </defs>
  </ChubbsWrapper>
));
