import React, { FC } from 'react';
import { ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import { Chubbs } from '@/components/ui/Chubbs/Chubbs';
import {
  ChubbsWorkingLight,
} from '@/components/ui/Chubbs/ChubbsWorking/ChubbsWorking.light';
import {
  ChubbsWorkingDark,
} from '@/components/ui/Chubbs/ChubbsWorking/ChubbsWorking.dark';

export const ChubbsWorking: FC<ChubbsProps> = React.memo((props) => (
  <Chubbs
    LightChubbs={ChubbsWorkingLight}
    DarkChubbs={ChubbsWorkingDark}
    {...props}
  />
));
